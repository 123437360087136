import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Tokens } from "../../../config/config";
import FixedTokens from "./FixedTokens";
import FlexibleTokens from "./FlexibleTokens";
import CrossFixedTokens from "./CrossFixedTokens";
import CrossFlexibleTokens from "./CrossFlexibleTokens";
import { appContext } from "../../../AppContext";
import { ethers } from "ethers";

export default function UserTable() {
  const { getContractFixed, getContractFlexible, provider, getUSDTAmount, getContractCrossFixed, getContractCrossFlexible } =
    useContext(appContext);

  const [fixedTokens, setFixedTokens] = useState([]);
  const [flexibleTokens, setFlexibleTokens] = useState([]);
  const [fixedLoading, setFixedLoading] = useState(true);
  const [flexibleLoading, setFlexibleLoading] = useState(true);

  const [crossFixedTokens, setCrossFixedTokens] = useState(null);
  const [crossFlexibleTokens, setCrossFlexibleTokens] = useState(null);
  const [crossFixedLoading, setCrossFixedLoading] = useState(true);
  const [crossFlexibleLoading, setCrossFlexibleLoading] = useState(true);

  const getTokensTotalFixed = async () => {
    try {
      const contract = getContractFixed();
      const apy = await contract.getAllLockTimePer();

      let temparr = [];
      for (let i = 0; i < Tokens.length; i++) {
        let element = Tokens[i];

        let _tokenApy = apy?.filter((item) => item?.token?.toLowerCase() === element.address?.toLowerCase() && item.status === true);

        let tokenApy = _tokenApy.map((item) => {
          return {
            month: item?.month?.toString(),
            apy: ethers.utils.formatEther(item?.apy),
            status: item?.status,
          };
        });
        let totalWei = await contract.getTotalFarmingOfToken(element.address);
        const tvl = await getUSDTAmount(element.address, totalWei);
        let obj = { ...element, totalFaming: ethers.utils.formatEther(totalWei), tvl, tokenApy: [...tokenApy] };
        temparr.push(obj);
      }

      setFixedTokens([...temparr]);
      setFixedLoading(false);
    } catch (err) {
      // console.log("error in getTokensTotalFixed : ", err);
    }
  };

  useEffect(() => {
    if (Tokens && provider) {
      setFixedLoading(true);
      setFixedTokens([...Tokens]);
      getTokensTotalFixed();
    }
  }, [Tokens, provider]);

  const getTokensTotalFlexible = async () => {
    try {
      const contract = getContractFlexible();
      const apy = await contract.getAllLockTimePer();

      let temparr = [];
      for (let i = 0; i < Tokens.length; i++) {
        let element = Tokens[i];
        let tokenApy = apy?.find((item) => item?.token?.toLowerCase() === element.address?.toLowerCase() && item.status === true);

        let totalWei = await contract.getTotalFarmingOfToken(element.address);
        const tvl = await getUSDTAmount(element.address, totalWei);
        let obj = {
          ...element,
          tvl,
          totalFaming: ethers.utils.formatEther(totalWei),
          tokenApy: ethers.utils.formatEther(tokenApy.apy),
        };
        temparr.push(obj);
      }

      setFlexibleTokens([...temparr]);
      setFlexibleLoading(false);
    } catch (err) {
      // console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (Tokens) {
      setFlexibleLoading(true);
      setFlexibleTokens([...Tokens]);
      getTokensTotalFlexible();
    }
  }, [Tokens, provider]);

  // ---------------------- cross contract ----------------------

  const getTokensTotalCrossFixed = async () => {
    try {
      const contract = getContractCrossFixed();
      const apy = await contract.getAllLockTimePer();
      let element = Tokens[0];

      let tokenApy = apy.map((item) => {
        return {
          month: item.month?.toString(),
          apy: ethers.utils.formatEther(item.apy),
          status: item?.status,
        };
      });
      let totalWei = await contract.getTotalFarming();
      const tvl = await getUSDTAmount(element.address, totalWei);
      element = { ...element, totalFaming: ethers.utils.formatEther(totalWei), tvl, tokenApy: [...tokenApy] };

      setCrossFixedTokens({ ...element });
      setCrossFixedLoading(false);
    } catch (err) {
      // console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (Tokens) {
      setCrossFixedLoading(true);
      setCrossFixedTokens([...Tokens]);
      getTokensTotalCrossFixed();
    }
  }, [Tokens, provider]);

  const getTokensTotalCrossFlexible = async () => {
    try {
      const contract = getContractCrossFlexible();
      const apy = await contract.getLockTimePer();

      let element = Tokens[0];

      let totalWei = await contract.getTotalFarming();
      const tvl = await getUSDTAmount(element.address, totalWei);
      element = {
        ...element,
        tvl,
        totalFaming: ethers.utils.formatEther(totalWei),
        tokenApy: ethers.utils.formatEther(apy),
      };

      setCrossFlexibleTokens({ ...element });
      setCrossFlexibleLoading(false);
    } catch (err) {
      // console.log("error : ", err);
    }
  };

  useEffect(() => {
    if (Tokens) {
      setCrossFlexibleLoading(true);
      setCrossFlexibleTokens([...Tokens]);
      getTokensTotalCrossFlexible();
    }
  }, [Tokens, provider]);

  return (
    <>
      {/* row */}
      <div className="row">
        <div className="col-xxl-12">
          <div className="card">
            <div className="card-header border-0 pb-2 flex-wrap">
              {/* <div class="d-flex"> */}
              <h4 className="heading me-2">Tokens</h4>
              <nav>
                <div className="order nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className="nav-link active"
                    id="nav-order-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#fixed"
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Fixed
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#flexible"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Flexible
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crossfixed"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Hybrid Heal
                  </button>
                  <button
                    className="nav-link"
                    id="nav-histroy-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#crossflexible"
                    type="button"
                    role="tab"
                    aria-selected="false"
                  >
                    Hybrid Relax
                  </button>
                </div>
              </nav>
              {/* </div> */}
            </div>
            <div className="card-body pt-2">
              <div className="tab-content" id="nav-tabContent">
                <FixedTokens tokens={fixedTokens} />
                <FlexibleTokens tokens={flexibleTokens} />
                <CrossFixedTokens tokens={crossFixedTokens} />
                <CrossFlexibleTokens tokens={crossFlexibleTokens} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
