import React, { useContext, useEffect } from "react";
import { appContext } from "../../../AppContext";

const FarmButton = ({ spinner, amount, balance, selectedApy, selectedToken, allowance, approveToken, farm }) => {
  const { isConnected, connectWallet } = useContext(appContext);
  return (
    <>
      {spinner ? (
        <div className="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : !isConnected ? (
        <button type="button" className="btn btn-primary w-100" onClick={connectWallet}>
          Connect Wallet
        </button>
      ) : !amount || Number(amount) <= 0 ? (
        <button type="button" className="btn btn-primary w-100" disabled>
          Enter amount
        </button>
      ) : Number(amount) > Number(balance) ? (
        <button type="button" className="btn btn-primary w-100" disabled>
          Insufficiant balance
        </button>
      ) : !selectedApy ? (
        <button type="button" className="btn btn-primary w-100" disabled>
          Select APY
        </button>
      ) : !selectedToken ? (
        <button type="button" className="btn btn-primary w-100" disabled>
          Select harvest token
        </button>
      ) : Number(allowance) < Number(amount) ? (
        <button type="button" className="btn btn-primary w-100" disabled>
          Farm
        </button>
      ) : (
        <button type="button" className="btn btn-primary w-100" onClick={farm}>
          Farm
        </button>
      )}
    </>
  );
};

export default FarmButton;
