import React, { useEffect, useState, useContext } from "react";
import Sidebar from "../../Coman/Sidebar";
import { Tooltip } from "react-tooltip";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Tokens } from "../../../config/config";
import SelectToken from "../../Modal/SelectToken";
import { appContext } from "../../../AppContext";
import { ethers } from "ethers";
import { formatAddress, formatAmount } from "../../../functions/common";
import DropdownSelectBox from "../../Coman/DropdownSelectBox";
import FarmButton from "./FarmButton";

export default function CrossFlexibleDetails() {
  const {
    getContractFlexible,
    provider,
    getTokenBalance,
    address,
    checkAllowane,
    giveAllowance,
    toastSuccess,
    getContractCrossFlexible,
    toastError,
    getUSDTAmount,
  } = useContext(appContext);
  const navigate = useNavigate();
  const [tokenDetails, setTokenDetails] = useState(null);
  const [tokenApy, setTokenApy] = useState([]);
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("0.0");
  const [allowance, setAllowance] = useState("0.0");
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedToken, setSelectedToken] = useState(null);
  const [rewardInfo, setRewardInfo] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);
  const [hash, setHash] = useState("");

  const [error, setError] = useState("");

  const token = searchParams.get("token");

  const [apyInfo, setApyInfo] = useState(null);
  const [apyInfoLoading, setApyInfoLoading] = useState(false);

  const getApyInfo = async () => {
    try {
      setApyInfoLoading(true);
      const contract = getContractCrossFlexible(token);

      let totalWei = await contract.getTotalFarming();
      const tvl = await getUSDTAmount(tokenDetails?.address, totalWei);

      setApyInfo({ tvl: tvl, totalFarming: ethers.utils.formatEther(totalWei) });
      setApyInfoLoading(false);
    } catch (err) {
      // console.log("error in getApyInfo : ", err);
      setApyInfoLoading(false);
    }
  };

  useEffect(() => {
    if (tokenDetails) {
      getApyInfo();
    }
  }, [tokenDetails]);

  useEffect(() => {
    if (token) {
      let tokend = Tokens.find((item) => item.address?.toLowerCase() === token.toLowerCase());
      if (tokend) {
        getMoreTokenInfo(tokend);
      } else {
        navigate("/error");
      }
    } else {
      navigate("/error");
    }
  }, [token, provider, address]);

  const getMoreTokenInfo = async (tokend) => {
    try {
      if (provider) {
        const contract = getContractCrossFlexible();
        const tokenAddress = await contract.getFarmingToken();
        if (tokend?.address?.toLowerCase() === tokenAddress?.toLowerCase()) {
          const _tokenApy = ethers.utils.formatEther(await contract.getLockTimePer());

          if (_tokenApy) {
            setTokenApy({ id: 1, apy: _tokenApy, address: tokenAddress?.toLowerCase(), status: Number(_tokenApy) > 0 ? true : false });
          }
        }
      } else {
        setError("Invalid token for farming.");
      }
      setTokenDetails(tokend);
      setLoading(false);
    } catch (err) {
      // console.log("err in getMoreTokenInfo : ", err);
      setLoading(false);
    }
  };

  const setAllowanceAndBalance = async () => {
    try {
      const bal = await getTokenBalance(tokenDetails);
      setBalance(bal);
      const allowance = await checkAllowane(tokenDetails, address, process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE);
      setAllowance(allowance);
    } catch (err) {
      // console.log("error in setAllowanceAndBalance : ", err);
    }
  };

  useEffect(() => {
    if (address && tokenDetails) {
      setAllowanceAndBalance();
    } else {
      setBalance("0");
      setAllowance("0");
    }
  }, [address, tokenDetails]);

  const approveToken = async () => {
    try {
      setBtnLoading(true);
      const result = await giveAllowance(tokenDetails.address, process.env.REACT_APP_CONTRACT_CROSS_FLEXIBALE);
      if (result) {
        setAllowanceAndBalance();
        toastSuccess("Approve successfully.");
      }
      setBtnLoading(false);
    } catch (err) {
      // console.log("error in approveToken : ", err);
      setBtnLoading(false);
    }
  };

  const getRewardInfo = async () => {
    try {
      const contract = await getContractCrossFlexible();
      const info = await contract.getFarmingRewardInfoBeforeFarm(
        selectedToken?.address,
        tokenDetails.address,
        ethers.utils.parseUnits(amount?.toString(), tokenDetails?.decimals)
      );

      setRewardInfo({
        dailyPer: ethers.utils.formatEther(info.dailyPer),
        dailyReward: ethers.utils.formatEther(info.dailyReward),
      });
      setHash("");
    } catch (err) {
      // console.log("error in getRewardInfo : ", err);
    }
  };

  useEffect(() => {
    if (provider && amount && Number(amount) > 0 && selectedToken) {
      getRewardInfo();
    } else {
      setRewardInfo(null);
    }
  }, [provider, amount, selectedToken]);

  const farm = async () => {
    try {
      setHash("");
      setBtnLoading(true);
      const contract = await getContractCrossFlexible();
      const tokenStatus = await contract.checkHarvestTokenStatus(selectedToken?.address);
      if (tokenStatus) {
        const tx = await contract.farm(selectedToken?.address, ethers.utils.parseUnits(amount));
        const receipt = await tx.wait();
        if (receipt?.transactionHash) {
          setAmount("");
          setSelectedToken(null);
          setHash(receipt?.transactionHash);
          toastSuccess("Farm successfully.");
          setAllowanceAndBalance();
        } else {
          return false;
        }
      } else {
        toastError("This token is currently not available for harvest.");
      }
      setBtnLoading(false);
    } catch (err) {
      // console.log("error in farm : ", err);
      setBtnLoading(false);
    }
  };

  return (
    <>
      <div id="main-wrapper" className="show">
        <Sidebar />

        <div>
          <div class="content-body">
            {loading ? (
              <div className="d-flex justify-content-center my-5">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : error ? (
              <div className="d-flex justify-content-center p-3">
                <h5 className="text-danger">{error}</h5>
              </div>
            ) : (
              <div class="container-fluid">
                <div className="profile card card-body p-3">
                  <div className="row align-items-center justify-content-center">
                    <div className="col-xl-3 col-lg-12  col-md-6 d-flex justify-content-center ">
                      <div className="profile-head">
                        <div className="profile-info d-flex align-items-center">
                          <div className="profile-photo mb-0">
                            <a className="test-popup-link" href="../error_404.html">
                              <img src={tokenDetails?.icon} className=" rounded-circle" alt="" style={{ height: "50px", width: "80px" }} />
                            </a>
                          </div>
                          <div className="profile-details">
                            <div className="profile-name px-3 pt-2">
                              <h5 className="text-primary mb-0">{tokenDetails?.symbol}</h5>
                              <p className="m-0">{tokenDetails?.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {apyInfoLoading ? (
                      <div className="col-xl-9 col-lg-12 col-md-6 d-flex justify-content-center">
                        <div class="spinner-border text-primary" role="status" style={{ height: "25px", width: "25px" }}>
                          <span class="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : (
                      <div className="col-xl-9">
                        <div className="row justify-content-center">
                          <div className="col-xl-3 col-lg-6  col-md-6 d-flex justify-content-center ">
                            <div>
                              <h5 className="text-primary mb-0 text-center">Total Farming</h5>
                              <p className="m-0 text-center">
                                {apyInfo?.totalFarming ? formatAmount(apyInfo?.totalFarming) + " " + tokenDetails?.symbol : "0"}
                              </p>
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-6  col-md-6 d-flex justify-content-center ">
                            <div>
                              <h5 className="text-primary mb-0 text-center">TVL</h5>
                              <p className="m-0 text-center">{apyInfo?.tvl ? "$" + formatAmount(apyInfo?.tvl) : "0"}</p>
                            </div>
                          </div>
                          {/* <div className="col-xl-3 col-lg-6  col-md-6 d-flex justify-content-center ">
                            <div>
                              <h5 className="text-primary mb-0 text-center">Onmac</h5>
                              <p className="m-0 text-center">Onmac</p>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xl-5">
                    <div className="card ">
                      <div className="card-header border-0 pb-0 rounded-5">
                        <h4 className="heading">Farm</h4>
                      </div>
                      <div className="card-body pt-4">
                        <form>
                          <div className="row mb-3">
                            <div className="col-xl-12">
                              <label className="form-label">Amount</label>
                              <div className="input-group mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  style={{ height: "44px" }}
                                  placeholder="0"
                                  value={amount}
                                  onChange={(e) => {
                                    if (e.target.value.match(/^([0-9]{1,})?(\.)?([0-9]{1,})?$/)) {
                                      setAmount(e.target.value);
                                    }
                                  }}
                                />

                                <button
                                  className="btn btn-primary btn-outline-primary border"
                                  style={{ height: "46px", marginTop: "-1px" }}
                                  type="button"
                                  onClick={() => setAmount(balance)}
                                >
                                  Max
                                </button>
                              </div>

                              <div className="d-flex justify-content-between">
                                <h6>Balance:</h6>
                                <h6>
                                  {formatAmount(balance)} {tokenDetails?.symbol}
                                </h6>
                              </div>
                              <label className="form-label">Lock Time : 1 month {tokenApy.apy}% APY</label>
                              <br />
                              <label className="form-label">Harvest Token :</label>
                              <DropdownSelectBox tokens={Tokens} selected={selectedToken} setSelected={setSelectedToken} />
                            </div>
                            <div className="d-flex justify-content-between mt-3">
                              <h6>Reward Info:</h6>
                            </div>
                            {rewardInfo ? (
                              <div className="col-xl-12">
                                <div className="d-flex flex-wrap justify-content-between mb-1">
                                  <div>Daily Percentage : </div>
                                  <div className=""> {formatAmount(rewardInfo?.dailyPer)} %</div>
                                </div>
                                <div className="d-flex flex-wrap justify-content-between">
                                  <div>Daily Reward :</div>
                                  <div className="">
                                    {" "}
                                    {formatAmount(rewardInfo?.dailyReward)} {selectedToken?.symbol}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <p className="text-danger">Enter an amount and select harvest token to view Reward information.</p>
                            )}
                          </div>
                          {hash ? (
                            <p className="d-flex justify-content-center">
                              <a href={`${process.env.REACT_APP_EXPLORER_URL}tx/${hash}`} target="_blank">
                                {formatAddress(hash)}
                              </a>
                            </p>
                          ) : (
                            ""
                          )}
                          {Number(allowance) < Number(amount) && !btnLoading ? (
                            <button type="button" className="btn btn-primary w-100 mb-2" onClick={approveToken}>
                              Approve Token
                            </button>
                          ) : (
                            ""
                          )}
                          <FarmButton
                            spinner={btnLoading}
                            amount={amount}
                            balance={balance}
                            selectedToken={selectedToken}
                            allowance={allowance}
                            approveToken={approveToken}
                            farm={farm}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <SelectToken />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
